const required = (v: any) => !!v || 'El campo es requerido';
export const rolesForm = [
  {
    label: 'Nombre',
    type: 'v-text-field',
    rules: [required],
    field: 'name',
    format: 'col-12',
  },
  {
    label: 'Permisos',
    type: 'v-autocomplete',
    rules: [],
    field: 'permissions',
    format: 'col-12',
    multiple: true,
  },
];
