
import { Component, Vue } from 'vue-property-decorator';
import { rolesForm } from '@/config/forms/roles';

@Component({})
export default class Roles extends Vue {
  path = 'roles';
  formRoles = rolesForm;
  headers = [{
    text: 'Nombre',
    value: 'name',
  }, {
    text: 'Creado',
    value: 'created',
  }, {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  }];
  selectInfo = {
    permissions: [],
  };

  async getPermissions() {
    const req: any = await this.$http.get(`/permissions/all`);
    this.selectInfo.permissions = req.data;
  }

  async mounted() {
    await this.getPermissions();
  }
}
